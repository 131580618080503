"use client"
import {useForm} from "react-hook-form"
import {Button} from "../../../components/ButtonLink"
import {auth} from "../../../firebase"
import {useRouter} from "next/navigation"
import {
  type UserCredential,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  AuthErrorCodes,
} from "firebase/auth"
import {useEffect, useState, useTransition} from "react"
import {z} from "zod"
import {zodResolver} from "@hookform/resolvers/zod"
import {FirebaseError} from "firebase/app"
import {useTranslations} from "next-intl"
import StyledLabel from "@/components/forms/StyledLabel"
import StyledInput from "@/components/forms/StyledInput"
import StyledField from "@/components/forms/StyledField"
import {redirectBack} from "./actions"
import Link from "next/link"
import {$path} from "next-typesafe-url"

const googleProvider = new GoogleAuthProvider()

googleProvider.setCustomParameters({prompt: "select_account"})

const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
})

const LoginPage = () => {
  const t = useTranslations("LoginPage")

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(async (registrations) => {
      console.log(registrations)
      const result = await Promise.all(registrations.map((v) => v.unregister()))
      if (result.length > 0) window.location.reload()
    })
  }, [])

  const {register, handleSubmit} = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
  })

  const [isPending, startTransaction] = useTransition()

  const [wrongCredentials, setWrongCredentials] = useState<boolean>(false)

  const signIn = async (signInFn: () => Promise<UserCredential>) => {
    try {
      const cred = await signInFn()

      const token = await cred.user.getIdToken()

      await fetch("/api/login", {headers: {Authorization: `Bearer ${token}`}})

      await redirectBack()
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (
          e.code === AuthErrorCodes.INVALID_PASSWORD ||
          e.code === AuthErrorCodes.INVALID_LOGIN_CREDENTIALS ||
          e.code === AuthErrorCodes.INVALID_EMAIL ||
          e.code === AuthErrorCodes.USER_DELETED
        ) {
          setWrongCredentials(true)
          return
        }
      }

      throw e
    }
  }

  const onSubmit = handleSubmit((data) => {
    startTransaction(async () => {
      await signIn(() => signInWithEmailAndPassword(auth, data.email, data.password))
    })
  })

  return (
    <>
      <form className="relative flex flex-col gap-3" onSubmit={onSubmit}>
        <StyledField flow="col">
          <StyledLabel>{t("email")}</StyledLabel>
          <StyledInput type="email" {...register("email")} />
        </StyledField>
        <StyledField flow="col">
          <StyledLabel>{t("pw")}</StyledLabel>
          <StyledInput type="password" {...register("password", {required: true})} />
        </StyledField>
        <Link className="text-left text-sm text-gray-700" href={$path({route: "/forgot-password"})}>
          {t("forgot-password")}
        </Link>
        <div className="grid grid-flow-col justify-stretch gap-1 self-stretch">
          <Button intent="primary">{t("login")}</Button>
          <Button
            intent="primary"
            type="button"
            onClick={() =>
              startTransaction(async () => {
                await signIn(() => signInWithPopup(auth, googleProvider))
              })
            }
          >
            {t("loginWithGoogle")}
          </Button>
        </div>
      </form>
      <div className="h-20 p-4">
        {isPending && <span className="font-semibold">{t("progress")}</span>}
        {!isPending && wrongCredentials && <span className="text-error-500">{t("wrong-credentials")}</span>}
      </div>
    </>
  )
}

export default LoginPage
